import React, { useState, useEffect } from "react"
import * as fcl from "@onflow/fcl"
import DappLib from "../blockchain/dapp-lib";
import Alert from "../components/alert";
import * as utils from '../utils';
import globalConfig from '../globalConfig';

var initpage = true; //当前页面是否首次加载，用于已登录退出钱包
var postrun = false; //当前是否已提交登录信息至服务器
var clicklogin = false;  //是否手动点击登录

let addr = utils.getQueryVariable('addr');
let formpage = utils.getQueryVariable('formpage');
let lang = utils.getQueryVariable('lang') ? utils.getQueryVariable('lang'):'en-us';
 
const provisionaccountTransactionCode = `\
import NonFungibleToken from 0x1d7e57aa55817448
import Openview from 0xa84a20ec6347ddc9

// This transaction is what an account would run
// to set itself up to receive NFTs
transaction {

    prepare(signer: AuthAccount) {
        // Return early if the account already has a collection
        if signer.borrow<&Openview.Collection>(from: Openview.CollectionStoragePath) != nil {
            return
        }

        // Create a new empty collection
        let collection <- Openview.createEmptyCollection()

        // save it to the account
        signer.save(<-collection, to: Openview.CollectionStoragePath)

        // create a public capability for the collection
        signer.link<&{NonFungibleToken.CollectionPublic, Openview.OpenviewCollectionPublic}>(
          Openview.CollectionPublicPath,
            target: Openview.CollectionStoragePath
        )
    }
}
`


const SignInOutButton = ({ user }) => {
  const [runstatus, setRun] = useState(0);
  const signInOrOut = async (event) => {
    event.preventDefault()

    if (user.loggedIn) {
      if(runstatus == 0){
        setRun(1);
        provisionaccountTransaction(user,setRun)
      }else if(runstatus == 1){
        return true;
      }
    } else {
      clicklogin = true;
      fcl.authenticate()
    }

  }

 
  var btnText = '';

  if (user.loggedIn) {
    if(runstatus == 0){
      btnText = utils.getLangText(lang,'provisionaccount_btn');
    }else if(runstatus == 1){
      btnText = utils.getLangText(lang,'loading');
    }
  }else{
    btnText =utils.getLangText(lang,'login_btn');
  }


  return (
    <div className="sign-button blue" onClick={signInOrOut}>
      {btnText}
    </div>
  )
}


const messageAlert=(string)=>{
  Alert.open({
      alertTip:string,
      closeAlert:function(){
          fcl.unauthenticate(); 
          console.log("关闭了...");
      }
  });
}


const provisionaccountTransaction = async (userinfo,setRunStatus) => {

  try {
    const { transactionId } = await fcl.send([
      fcl.transaction(provisionaccountTransactionCode),
      //fcl.args([ fcl.arg("/storage/openviewNFTCollection", t.Optional(t.String)) ]), 
      fcl.proposer(fcl.currentUser().authorization),
      fcl.authorizations([
        fcl.currentUser().authorization,
      ]),
      fcl.payer(fcl.currentUser().authorization),
      fcl.limit(1000),
    ])
  
    const unsub = fcl
    .tx({ transactionId })
    .subscribe(transaction => {
      //此处 setRunStatus 可以设置一个状态为“正在等待交易结果”
      if (fcl.tx.isSealed(transaction)) {
        console.log(transaction);
        setRunStatus(0)
        if(transaction.statusCode == 0 && transaction.errorMessage == ''){
          sendProvisionSuccess(transactionId,userinfo);
          messageAlert(utils.getLangText(lang,'provision_success'));
        }else{
          messageAlert(utils.getLangText(lang,'provision_fail'));
        }
       
        //unsub()
      }
    })

  } catch (error) {
    setRunStatus(0)
    console.error(error);
    messageAlert(utils.getLangText(lang,'provision_fail'));
  }

}


const sendProvisionSuccess = async(transactionId,userinfo) => { 
  const bodydata = {'addr':userinfo.addr}
  //const bodydata = {'addr':userinfo.addr,'email':userinfo.services[0].scoped.email}
  fetch(globalConfig.mallurl+'/index.php/Openview/provisionaccount',{
    method:'POST',
    //mode:'no-cors',
    headers:{
        //'Accept':'application/json,text/plain,*/*',
        'Content-Type':'application/x-www-form-urlencoded'
    },
    body:JSON.stringify(bodydata)
  }).then((response)=>{
    return response.json()
  }).then((data)=>{
    //console.log(data)
    if(data.errno == 0){
      window.location.href=globalConfig.mallurl+"/index.php/Member/blockchainprovisionaccount";
    } 
  }).catch(function(error){
    //console.log(error)
  })
}



const CurrentUser = () => {
 
  const [user, setUser] = useState({})
  useEffect(() =>
    fcl
      .currentUser()
      .subscribe(user => setUser({ ...user }))
    , [])
    //fcl.unauthenticate();
  if(user && user.loggedIn && clicklogin && !postrun){
      if(user.addr != addr){
        messageAlert(utils.getLangText(lang,'provision_wallet_error'));
      }
  }
  return (
      <SignInOutButton user={user} />
  )
}


export default CurrentUser
