import React from 'react';
import ReactDOM from 'react-dom';
import DappLib from "./blockchain/dapp-lib";
import styled from 'styled-components'
import * as utils from './utils';

import './App.css';
import "./config"


import Section from './components/Section'
import Header from './components/Header'
import Footer from './components/Footer'
import commoncss from './resource/common.css'

import OpenviewProvisionAccount from './lib/OpenviewProvisionAccount';
import DepositNFT from './lib/DepositNFT';




let userAccount = null;
let dataId = null;
let dataFile = null;


const Wrapper = styled.div`
  font-size: 13px;
  font-family: Arial, Helvetica, sans-serif;
`;


 
userAccount = utils.getQueryVariable('addr');
dataId = utils.getQueryVariable('dataid');
dataFile = utils.getQueryVariable('datafile');
let action = utils.getQueryVariable('action');
let lang = utils.getQueryVariable('lang') ? utils.getQueryVariable('lang'):'en-us';

function App() {

 if(action === 'provisionAccount'){
    return (
      <Wrapper>
      <Header className="header">
            <div className="header-left">{utils.getLangText(lang,'provisionaccount')}</div>
            <div className="header-right"></div>
            <div className="clear"></div>
        </Header>
        <Section>
          <div className="provision-addr">
          {utils.getLangText(lang,'now_provision_wallet')}：{userAccount}
          </div>
          <div className="provision-bottom">
            <div className="provision-bottom-wrapper">{utils.getLangText(lang,'provisiontips')}</div>
          </div>
          <OpenviewProvisionAccount addr={userAccount}/>
        
        </Section>
        <Footer className="footer">
        © 2022 OPENVIEW.WORLD
        </Footer>
     </Wrapper>
   );
  }else if(action === 'depositNft'){
    return (
      <Wrapper>
      <Header className="header">
            <div className="header-left">{utils.getLangText(lang,'deposit_nft')}</div>
            <div className="header-right"></div>
            <div className="clear"></div>
        </Header>
        <Section>
          <div className="provision-addr">
            {utils.getLangText(lang,'now_deposit_uid')}：{dataId}
          </div>
          <div className="provision-bottom">
            <div className="provision-bottom-wrapper">{utils.getLangText(lang,'deposittips')}</div>
          </div>
          <DepositNFT/>
        
        </Section>
        <Footer className="footer">
          © 2022 OPENVIEW.WORLD
        </Footer>
     </Wrapper>
   );

    //withdrawRun();
  }

}


export default App;
