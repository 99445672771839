import { config } from "@onflow/fcl";
import {send as grpcSend} from "@onflow/transport-grpc"
config({
  "accessNode.api": "https://flow-access-mainnet.portto.io", //https://flow-access-mainnet.portto.io flow-testnet.g.alchemy.com:443 https://access.onflow.org
  //"accessNode.api": "https://flow-mainnet.g.alchemy.com",
  //"grpc.metadata":{"api_key": "4oWZbwj_pL8rAdqE_pojtDG8420-nad4"},
  "discovery.wallet": "https://fcl-discovery.onflow.org/authn",
  "0xProfile": "0xa84a20ec6347ddc9", // The account address where the smart contract lives
  "sdk.transport": grpcSend
})
/*
config({
  "accessNode.api": "http://localhost:8080",
  "discovery.wallet": "http://localhost:8701/fcl/authn",
  "0xProfile": "0xf8d6e0586b0a20c7" // The account address where the smart contract lives
})

config({
  "accessNode.api": "https://flow-access-mainnet.portto.io", //https://flow-access-mainnet.portto.io flow-testnet.g.alchemy.com:443 https://access.onflow.org
  "discovery.wallet": "https://fcl-discovery.onflow.org/authn",
  "0xProfile": "0x60ef868de35f9728" // The account address where the smart contract lives
})*/