import React, { useState, useEffect } from "react"

import ReactDOM from 'react-dom';
import DappLib from "./blockchain/dapp-lib";
import App from './App';
import Result from './Result';
import * as utils from './utils';
import globalConfig from './globalConfig';

let userAccount = null;
let dataId = null;


userAccount = utils.getQueryVariable('addr');
dataId = utils.getQueryVariable('dataid');
let action = utils.getQueryVariable('action');
let lang = utils.getQueryVariable('lang') ? utils.getQueryVariable('lang'):'en-us';


if(action === 'provisionAccount' || action === 'depositNft'){
  ReactDOM.render(
    <React.StrictMode>
     <App />
    </React.StrictMode>,
    document.getElementById('root')
  );
}else{
  ReactDOM.render(
    <React.StrictMode>
         <Result data='' datalabel="交易正在执行中，整个过程30秒-1分钟不等，请耐心等待···" />
    </React.StrictMode>,
    document.getElementById('root')
  );
  if(action == 'mintToken2662'){
    mintNFT();
  }else if(action == 'mintProvisionAccount'){
    provisionAccount();
  }else if(action == 'destroyNft'){
    destroyNft();
  }else if(action == 'transferNft9265'){
    transferNft();
  }else if(action == 'getTotalSupply'){
    getTotalSupply();
  }else if(action == 'borrowNFT'){
    borrowNFT();
  }else if(action == 'getCollection'){
    getCollection();
  }else if(action == 'getCollectionLength'){
    getCollectionLength();
  }else if(action == 'getNFTMetadata'){
    getNFTMetadata();
  }

  
} 
function mintNFT(){
  let action = 'mintNFT';
  let values = {'recipient':userAccount,
                'name': decodeURIComponent(utils.getQueryVariable('name')),
                'description':decodeURIComponent(utils.getQueryVariable('description')),
                'thumbnail':decodeURIComponent(utils.getQueryVariable('thumbnail'))
              }; 
  try {
    console.log('mintNFT , values: ', values);
    let retVal = DappLib[action].call(null, values);
    retVal.then(function(retVal) { 
      console.log(retVal);
      ReactDOM.render(
        <React.StrictMode>
             <Result data={retVal.result} datalabel="执行成功，交易哈希为：" />
        </React.StrictMode>,
        document.getElementById('root')
      );
      postData('mintnft',{'transactionid':retVal.result,'nftid':dataId,'addr':userAccount,'blockchainid':retVal.nftId});
     })
  } catch (e) {
    console.log(e);
  } finally {
    console.log('mintNFT finally');
  }
}
function provisionAccount(){
  let action = 'provisionAccount';
  let values = {};
  try {
    console.log('provisionAccount , values: ', values);
    let retVal = DappLib[action].call(null, values);
    retVal.then(function(retVal) { 
      console.log(retVal);
      ReactDOM.render(
        <React.StrictMode>
             <Result data={retVal.result} datalabel="执行成功，交易哈希为：" />
        </React.StrictMode>,
        document.getElementById('root')
      );
      //postData('transfertokensuccess',{'transactionid':retVal.result,'dataid':dataId});
     })
  } catch (e) {
    console.log(e);
  } finally {
    console.log('provisionAccount finally');
  }
}
function destroyNft(){
  let action = 'destroyNft';
  let values = {'id':utils.getQueryVariable('id')};
  try {
    console.log('destroyNft , values: ', values);
    let retVal = DappLib[action].call(null, values);
    retVal.then(function(retVal) { 
      console.log(retVal);
      ReactDOM.render(
        <React.StrictMode>
             <Result data={retVal.result} datalabel="执行成功，交易哈希为：" />
        </React.StrictMode>,
        document.getElementById('root')
      );
      //postData('transfertokensuccess',{'transactionid':retVal.result,'dataid':dataId});
     })
  } catch (e) {
    console.log(e);
  } finally {
    console.log('destroyNft finally');
  }
}
function transferNft(){
  let action = 'transferNft';
  let id = utils.getQueryVariable('id');
  let values = {'recipient':userAccount,'id':id};
 
  try {
    console.log('transferNft , values: ', values);
    let retVal = DappLib[action].call(null, values);
    retVal.then(function(retVal) { 
      console.log(retVal);
      ReactDOM.render(
        <React.StrictMode>
             <Result data={retVal.result} datalabel="执行成功，交易哈希为：" />
        </React.StrictMode>,
        document.getElementById('root')
      );
      postData('transfernft',{'transactionid':retVal.result,'dataid':dataId,'blockchainid':id,'addr':userAccount});
     })
  } catch (e) {
    console.log(e);
  } finally {
    console.log('transferNft finally');
  }
}
function getTotalSupply(){
  let action = 'getTotalSupply';
  let values = {};
  try {
    let retVal = DappLib[action].call(null, values);
    retVal.then(function(retVal) { 
      ReactDOM.render(
        <React.StrictMode>
             <Result data={retVal.result} datalabel="执行成功，当前NFT总量为：" />
        </React.StrictMode>,
        document.getElementById('root')
      );
      //postData('transfertokensuccess',{'transactionid':retVal.result,'dataid':dataId});
     })
  } catch (e) {
    console.log(e);
  } finally {
    console.log('getTotalSupply finally');
  }
}
function borrowNFT(){
  let action = 'borrowNFT';
  let values = {'address':userAccount,'id':utils.getQueryVariable('id')};
  console.log(values)
  try {
    let retVal = DappLib[action].call(null, values);
    retVal.then(function(retVal) { 
      ReactDOM.render(
        <React.StrictMode>
             <Result data={retVal.result} datalabel="执行成功，返回信息为：" />
        </React.StrictMode>,
        document.getElementById('root')
      );
      //postData('transfertokensuccess',{'transactionid':retVal.result,'dataid':dataId});
     })
  } catch (e) {
    console.log(e);
  } finally {
    console.log('borrowNFT finally');
  }
}
function getCollection(){
  let action = 'getCollection';
  let values = {'address':userAccount};
  console.log(values)
  try {
    let retVal = DappLib[action].call(null, values);
    retVal.then(function(retVal) { 
      retVal.result = JSON.stringify(retVal.result)
      ReactDOM.render(
        <React.StrictMode>
             <Result data={retVal.result} datalabel="执行成功，返回信息为：" />
        </React.StrictMode>,
        document.getElementById('root')
      );
      //postData('transfertokensuccess',{'transactionid':retVal.result,'dataid':dataId});
     })
  } catch (e) {
    console.log(e);
  } finally {
    console.log('getCollection finally');
  }
}
function getCollectionLength(){
  let action = 'getCollectionLength';
  let values = {'address':userAccount};
  console.log(values)
  try {
    let retVal = DappLib[action].call(null, values);
    retVal.then(function(retVal) { 
      ReactDOM.render(
        <React.StrictMode>
             <Result data={retVal.result} datalabel="执行成功，返回信息为：" />
        </React.StrictMode>,
        document.getElementById('root')
      );
      //postData('transfertokensuccess',{'transactionid':retVal.result,'dataid':dataId});
     })
  } catch (e) {
    console.log(e);
  } finally {
    console.log('getCollectionLength finally');
  }
}
function getNFTMetadata(){
  let action = 'getNFTMetadata';
  let values = {'address':userAccount,'id':utils.getQueryVariable('id')};
  console.log(values)
  try {
    let retVal = DappLib[action].call(null, values);
    retVal.then(function(retVal) { 
      retVal.result = JSON.stringify(retVal.result)
      ReactDOM.render(
        <React.StrictMode>
             <Result data={retVal.result} datalabel="执行成功，返回信息为：" />
        </React.StrictMode>,
        document.getElementById('root')
      );
      //postData('transfertokensuccess',{'transactionid':retVal.result,'dataid':dataId});
     })
  } catch (e) {
    console.log(e);
  } finally {
    console.log('getNFTMetadata finally');
  }
}
function postData(action,bodydata){
  fetch(globalConfig.mallurl+'/index.php/Openview/'+action,{
       method:'POST',
       //mode:'no-cors',
       headers:{
           //'Accept':'application/json,text/plain,*/*',
           'Content-Type':'application/x-www-form-urlencoded'
       },
       body:JSON.stringify(bodydata)
   }).then((response)=>{
       return response.json()
   }).then((data)=>{
     console.log(data)
   }).catch(function(error){
       console.log(error)
   })
}

