import React, { useState, useEffect } from "react"
import * as fcl from "@onflow/fcl"
import * as t from "@onflow/types"
import DappLib from "../blockchain/dapp-lib";
import Alert from "../components/alert";
import * as utils from '../utils';

import globalConfig from '../globalConfig';

var clicklogin = false;  //是否手动点击登录

let dataId = utils.getQueryVariable('dataid');
let lang = utils.getQueryVariable('lang') ? utils.getQueryVariable('lang'):'en-us';
 

const depositTransactionCode = `\
import NonFungibleToken from 0x1d7e57aa55817448
        import Openview from 0xa84a20ec6347ddc9

        // This transaction is for transferring and NFT from
        // one account to another
        transaction(recipient: Address, withdrawID: UInt64) {

            prepare(signer: AuthAccount) {
                // get the recipients public account object
                let recipient = getAccount(recipient)

                // borrow a reference to the signer's NFT collection
                let collectionRef = signer
                    .borrow<&Openview.Collection>(from: Openview.CollectionStoragePath)
                    ?? panic("Could not borrow a reference to the owner's collection")

                // borrow a public reference to the receivers collection
                let depositRef = recipient
                    .getCapability(Openview.CollectionPublicPath)
                    .borrow<&{NonFungibleToken.CollectionPublic}>()
                    ?? panic("Could not borrow a reference to the receiver's collection")

                // withdraw the NFT from the owner's collection
                let nft <- collectionRef.withdraw(withdrawID: withdrawID)

                // Deposit the NFT in the recipient's collection
                depositRef.deposit(token: <-nft)
            }
        }

`

const SignInOutButton = ({ user }) => {
  const [runstatus, setRun] = useState(0);
  const signInOrOut = async (event) => {
    event.preventDefault()

  
    if(runstatus == 0){
      clicklogin = true;
      setRun(1)
      fcl.authenticate()
    }else if(runstatus == 1){
      return true;
    }
    
  }
  var btnText = '';
  if(runstatus == 0){
    btnText = utils.getLangText(lang,'login_btn');
   }else if(runstatus == 1){
    btnText = utils.getLangText(lang,'loading');
  }
  

  return (
    <div>
      <div className="sign-button blue" onClick={signInOrOut}>
      {btnText}
      </div>
    </div>
  )
}


const Wallet = ({ user }) => {
  const [user_nft_list, setUserNFTList] = useState({});

  const [deposit_status, setDepositStatus] = useState("Not started")
  const [deposit_transaction, setDepositTransaction] = useState(null)

  
  useEffect(() =>  getUserNFT(setUserNFTList,user), [])


  const changeDepositNFT = async (id) => {
    depositTransaction(id);
  }


  const depositTransaction = async (id) => {

    if(deposit_status == 'Resolving...'){
      return true;
    }
   

    setDepositStatus("Resolving...")
    let isSealed = false
    const blockResponse = await fcl.send(fcl.build([
        fcl.getBlock(isSealed)
      ])
    )

    const block = await fcl.decode(blockResponse)
    try {
 
      const { transactionId } = await fcl.send([
        fcl.transaction(depositTransactionCode),
        fcl.args([
          fcl.arg(globalConfig.deposit_recipient_account, t.Address),
          fcl.arg(parseInt(id), t.UInt64),
        ]),
        fcl.proposer(fcl.currentUser().authorization),
        fcl.authorizations([
          fcl.currentUser().authorization,
        ]),
        fcl.payer(fcl.currentUser().authorization),
        fcl.ref(block.id),
        fcl.limit(1000),
      ])

      setDepositStatus("Transaction sent, waiting for confirmation")

      const unsub = fcl
        .tx({ transactionId })
        .subscribe(transaction => {
          setDepositTransaction(transaction)
          if (fcl.tx.isSealed(transaction)) {
            setDepositStatus("Transaction is Sealed")
            console.log(transaction)
            getUserNFT(setUserNFTList,user);
            postData('depositnft',{'transactionid':transaction.events[1].transactionId,'uid':dataId,'blockchainid':id,'addr':user.addr});
            unsub()
          }
        })
    } catch (error) {
      console.error(error);
      setDepositStatus("Transaction failed")
    }
  }

 
  
  
  return (
    <div>
      <div className="transaction-code">Status: {deposit_status}</div>
      {deposit_transaction &&  <div className="transaction-code">{JSON.stringify(deposit_transaction, null, 2)}</div>}
      <div className="assets-section">
        <div className="assets-box">
          <div className="assets-box-num">
          {
        user_nft_list &&
        <div className="nft-box">
          {
            Object.keys(user_nft_list).map(k => {
              return (
                <div key={k} className="nft-box-item">
                  <img src={user_nft_list[k].info.thumb} className="nft-box-item-thumb" />
                  <div className="nft-box-item-name textline_two">{user_nft_list[k].info.name}</div>
                  <div className="nft-box-item-info textline_one">{user_nft_list[k].info.author}</div>
                  <div className="nft-box-item-info textline_one">{user_nft_list[k].info.seriesname}</div>
                  <div className="nft-box-item-info textline_one">#{user_nft_list[k].info.id}</div>
                  <button onClick={(e)=>changeDepositNFT(user_nft_list[k].id)} className="nft-box-item-btn">Deposit This</button>
                </div>
              )
            })
          }
         
        </div>
      }
       {
        !user_nft_list &&
          <div className="nft-nodata">No NFR</div>
      }
      </div>
      </div>

      </div>
  
    </div>
  )
}


const messageAlert=(string)=>{
  Alert.open({
      alertTip:string,
      closeAlert:function(){
          console.log("关闭了...");
      }
  });
}

const getUserNFT = async (setUserNFTList,user) => {
  let action = 'getCollection';
  let values = {'address':user.addr};
  try {
    let retVal = DappLib[action].call(null, values);
    retVal.then(function(retVal) { 
      formatNftList(setUserNFTList,retVal.result)
    })
  } catch (e) {
    messageAlert(utils.getLangText(lang,'query_fail'));
  } finally {
    //console.log('getBalance finally');
  }
}

const formatNftList = async (setUserNFTList,list) => {
  fetch(globalConfig.mallurl+'/index.php/Openview/querynftinfo',{
       method:'POST',
       //mode:'no-cors',
       headers:{
           //'Accept':'application/json,text/plain,*/*',
           'Content-Type':'application/x-www-form-urlencoded'
       },
       body:JSON.stringify(list)
   }).then((response)=>{
       return response.json()
   }).then((data)=>{
      setUserNFTList(data.data);
   }).catch(function(error){
       console.log(error)
   })
}

function postData(action,bodydata){
  fetch(globalConfig.mallurl+'/index.php/Openview/'+action,{
       method:'POST',
       //mode:'no-cors',
       headers:{
           //'Accept':'application/json,text/plain,*/*',
           'Content-Type':'application/x-www-form-urlencoded'
       },
       body:JSON.stringify(bodydata)
   }).then((response)=>{
       return response.json()
   }).then((data)=>{
     console.log(data)
   }).catch(function(error){
       console.log(error)
   })
}



const CurrentUser = () => {
 
  const [user, setUser] = useState({})
  useEffect(() =>
    fcl
      .currentUser()
      .subscribe(user => setUser({ ...user }))
    , [])
    //console.log(user)
    //fcl.unauthenticate();
  if(user && user.loggedIn){
    return (
     
      <Wallet user={user} />
    )
  }else{
    return (
      <SignInOutButton user={user} />
    )
  }
 
}


export default CurrentUser
