import React from 'react';
import ReactDOM from 'react-dom';

import styled from 'styled-components'
import * as utils from './utils';

import "./config"



const Wrapper = styled.div`
  font-size: 13px;
  font-family: Arial, Helvetica, sans-serif;
`;

const ResultBox = styled.div`
  background: #fff;
  max-width: 560px;    
  padding:30px;
  position: absolute;   
  top: 50%;   
  left: 50%;   
  -webkit-transform: translate(-50%, -50%);   
  -moz-transform: translate(-50%, -50%);   
  -ms-transform: translate(-50%, -50%);   
  -o-transform: translate(-50%, -50%);   
  transform: translate(-50%, -50%);   
  font-size:16px;
  border-radius:6px;
  word-break: break-word;

`;
function Result(props) {

    return (
      <Wrapper>
          <ResultBox>
          <div className="result">
          {props.datalabel}{props.data}
          </div>
          </ResultBox>
     </Wrapper>
   );
}





export default Result;