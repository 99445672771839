// 🚨🚨🚨🚨🚨🚨🚨🚨🚨🚨🚨🚨🚨🚨🚨🚨🚨🚨🚨🚨🚨🚨🚨🚨🚨🚨🚨🚨🚨🚨🚨🚨🚨
// ⚠️ THIS FILE IS AUTO-GENERATED WHEN packages/dapplib/interactions CHANGES
// DO **** NOT **** MODIFY CODE HERE AS IT WILL BE OVER-WRITTEN
// 🚨🚨🚨🚨🚨🚨🚨🚨🚨🚨🚨🚨🚨🚨🚨🚨🚨🚨🚨🚨🚨🚨🚨🚨🚨🚨🚨🚨🚨🚨🚨🚨🚨

import * as fcl from "@onflow/fcl";
export default class DappScripts {
    static get_totalsupply() {
		return fcl.script`
            import Openview from 0xa84a20ec6347ddc9
            pub fun main(): UInt64 {
                return Openview.totalSupply
            }
        `;
	}
	static borrow_nft() {
		return fcl.script`
            import NonFungibleToken from 0x1d7e57aa55817448
            import Openview from 0xa84a20ec6347ddc9

            // This script borrows an NFT from a collection
            pub fun main(address: Address, id: UInt64) {
                let account = getAccount(address)

                let collectionRef = account
                .getCapability(Openview.CollectionPublicPath)
                .borrow<&{NonFungibleToken.CollectionPublic}>()
                ?? panic("Could not borrow capability from public collection")

            // Borrow a reference to a specific NFT in the collection
            let _ = collectionRef.borrowNFT(id: id)
            } 
        `;
	}
    static get_collection() {
		return fcl.script`
        import NonFungibleToken from 0x1d7e57aa55817448
        import Openview from 0xa84a20ec6347ddc9
        
        pub fun main(address: Address): [UInt64] {
            let account = getAccount(address)
        
            let collectionRef = account
                .getCapability(Openview.CollectionPublicPath)
                .borrow<&{NonFungibleToken.CollectionPublic}>()
                ?? panic("Could not borrow capability from public collection")
            
            return collectionRef.getIDs()
        }
        `;
	}
    static get_collection_length() {
		return fcl.script`
        import NonFungibleToken from 0x1d7e57aa55817448
        import Openview from 0xa84a20ec6347ddc9
        
        pub fun main(address: Address): Int {
            let account = getAccount(address)
        
            let collectionRef = account
                .getCapability(Openview.CollectionPublicPath)
                .borrow<&{NonFungibleToken.CollectionPublic}>()
                ?? panic("Could not borrow capability from public collection")
            
            return collectionRef.getIDs().length
        }
        `;
	}
	static get_nft_metadata() {
		return fcl.script`
        import Openview from 0xa84a20ec6347ddc9
import MetadataViews from 0x1d7e57aa55817448

pub struct NFT {
    pub let name: String
    pub let description: String
    pub let thumbnail: String
    pub let owner: Address
    pub let type: String

    init(
        name: String,
        description: String,
        thumbnail: String,
        owner: Address,
        nftType: String,
    ) {
        self.name = name
        self.description = description
        self.thumbnail = thumbnail
        self.owner = owner
        self.type = nftType
    }
}

pub fun main(address: Address, id: UInt64): NFT {
    let account = getAccount(address)

    let collection = account
        .getCapability(Openview.CollectionPublicPath)
        .borrow<&{Openview.OpenviewCollectionPublic}>()
        ?? panic("Could not borrow a reference to the collection")

    let nft = collection.borrowOpenview(id: id)!

    // Get the basic display information for this NFT
    let view = nft.resolveView(Type<MetadataViews.Display>())!

    // Get the royalty information for the given NFT
    let display = view as! MetadataViews.Display
    
    let owner: Address = nft.owner!.address!
    let nftType = nft.getType()

    return NFT(
        name: display.name,
        description: display.description,
        thumbnail: display.thumbnail.uri(),
        owner: owner,
        nftType: nftType.identifier
    )
}
        `;
	}

}
