// 🚨🚨🚨🚨🚨🚨🚨🚨🚨🚨🚨🚨🚨🚨🚨🚨🚨🚨🚨🚨🚨🚨🚨🚨🚨🚨🚨🚨🚨🚨🚨🚨🚨
// ⚠️ THIS FILE IS AUTO-GENERATED WHEN packages/dapplib/interactions CHANGES
// DO **** NOT **** MODIFY CODE HERE AS IT WILL BE OVER-WRITTEN
// 🚨🚨🚨🚨🚨🚨🚨🚨🚨🚨🚨🚨🚨🚨🚨🚨🚨🚨🚨🚨🚨🚨🚨🚨🚨🚨🚨🚨🚨🚨🚨🚨🚨

import * as fcl from "@onflow/fcl";

export default class DappTransactions {

	static mint_nft() {
		return fcl.transaction`
        import NonFungibleToken from 0x1d7e57aa55817448
        import Openview from 0xa84a20ec6347ddc9
        import MetadataViews from 0x1d7e57aa55817448
        import FungibleToken from 0xf233dcee88fe0abe
        
        // This script uses the NFTMinter resource to mint a new NFT
        // It must be run with the account that has the minter resource
        // stored in /storage/NFTMinter
        transaction(
            recipient: Address,
            name: String,
            description: String,
            thumbnail: String,
        ) {
        
            // local variable for storing the minter reference
            let minter: &Openview.NFTMinter
        
        
        
            prepare(signer: AuthAccount) {
                // borrow a reference to the NFTMinter resource in storage
                self.minter = signer.borrow<&Openview.NFTMinter>(from: Openview.MinterStoragePath)
                    ?? panic("Could not borrow a reference to the NFT minter")
            }
        
        
        
            execute {
        
                // Borrow the recipient's public NFT collection reference
                let receiver = getAccount(recipient)
                    .getCapability(Openview.CollectionPublicPath)
                    .borrow<&{NonFungibleToken.CollectionPublic}>()
                    ?? panic("Could not get receiver reference to the NFT Collection")
        
                // Mint the NFT and deposit it to the recipient's collection
                self.minter.mintNFT(
                    recipient: receiver,
                    name: name,
                    description: description,
                    thumbnail: thumbnail
                )
            }
        }`;
	}
 
	static provision_account() {
		return fcl.transaction`
        import NonFungibleToken from 0x1d7e57aa55817448
        import Openview from 0xa84a20ec6347ddc9
        
        // This transaction is what an account would run
        // to set itself up to receive NFTs
        transaction {
        
            prepare(signer: AuthAccount) {
                // Return early if the account already has a collection
                if signer.borrow<&Openview.Collection>(from: Openview.CollectionStoragePath) != nil {
                    return
                }
        
                // Create a new empty collection
                let collection <- Openview.createEmptyCollection()
        
                // save it to the account
                signer.save(<-collection, to: Openview.CollectionStoragePath)
        
                // create a public capability for the collection
                signer.link<&{NonFungibleToken.CollectionPublic, Openview.OpenviewCollectionPublic}>(
                    Openview.CollectionPublicPath,
                    target: Openview.CollectionStoragePath
                )
            }
        }
		`;
	}
	static destroy_nft() {
		return fcl.transaction`
        import NonFungibleToken from 0x1d7e57aa55817448
        import Openview from 0xa84a20ec6347ddc9

        transaction(id: UInt64) {
            prepare(signer: AuthAccount) {
                let collectionRef = signer.borrow<&Openview.Collection>(from: Openview.CollectionStoragePath)
                    ?? panic("Could not borrow a reference to the owner's collection")

                // withdraw the NFT from the owner's collection
                let nft <- collectionRef.withdraw(withdrawID: id)

                destroy nft
            }
        }
 
		`;
	}
	static transfer() {
		return fcl.transaction`
        import NonFungibleToken from 0x1d7e57aa55817448
        import Openview from 0xa84a20ec6347ddc9

        // This transaction is for transferring and NFT from
        // one account to another
        transaction(recipient: Address, withdrawID: UInt64) {

            prepare(signer: AuthAccount) {
                // get the recipients public account object
                let recipient = getAccount(recipient)

                // borrow a reference to the signer's NFT collection
                let collectionRef = signer
                    .borrow<&Openview.Collection>(from: Openview.CollectionStoragePath)
                    ?? panic("Could not borrow a reference to the owner's collection")

                // borrow a public reference to the receivers collection
                let depositRef = recipient
                    .getCapability(Openview.CollectionPublicPath)
                    .borrow<&{NonFungibleToken.CollectionPublic}>()
                    ?? panic("Could not borrow a reference to the receiver's collection")

                // withdraw the NFT from the owner's collection
                let nft <- collectionRef.withdraw(withdrawID: withdrawID)

                // Deposit the NFT in the recipient's collection
                depositRef.deposit(token: <-nft)
            }
        }

 
		`;
	}
    static storageUsedBefore() {
		return fcl.transaction`
        import NonFungibleToken from 0x1d7e57aa55817448
      import ALYPHS from 0x60ef868de35f9728
        transaction {
          prepare(acct: AuthAccount) {
            let storageUsedBefore = acct.storageUsed
            panic(storageUsedBefore.toString())
          }
        }
		`;
	}
}

