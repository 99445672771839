
export function getLangText(lang,key){
    const enus = {
        dashboard:'Dashboard',
        market:'Marketplace',
        website:'Website',
        provisionaccount:'Activation of Openview receiving account',
        now_provision_wallet:'Wallet to be activated:',
        provisiontips:'Your current login wallet account has not been activated. You need to initiate a transaction to activate it once, and then you can withdraw the Openview into your personal wallet. There will be no other fees in the activation process except for the gas charged by the blockchain',
        deposit_nft:'Deposit Openview',
        now_deposit_uid:'Current Deposit UID',
        deposittips:'Please log in and select the NFR to recharge. After confirming that the selection is correct, click the recharge button to recharge',
        provisionaccount_btn:'Start activation',
        login_btn:'Login&SignIn with Flow Wallet',
        logout_btn:'Logout',
        loading:'Loading...',
        provision_success:'Activation succeeded',
        provision_fail:'Activation failed. Please try again later',
        provision_wallet_error:'The wallet currently logged in does not match the wallet to be activated. Please log in again',
        deposit_btn:'Confirm Deposit Openview',
        deposit_alyphs_num:'Deposit Openview Num',
        please_enter_depositnum:'Please enter deposit num',
        deposit_fail:'Deposit failed. Please check whether the wallet balance is sufficient',
        deposit_success:'Deposit succeeded'
    };
    
    const zhcn = {
        dashboard:'仪表板',
        market:'商城',
        website:'官网',
        provisionaccount:'Openview接收账户激活',
        now_provision_wallet:'当前待激活钱包',
        provisiontips:'您当前登录钱包账户尚未激活，您需要发起一笔交易一遍激活，激活后才可提取Openview到您的个人钱包内，激活过程除区块链所收取的GAS外，不会产生其他费用',
        deposit_nft:'Openview充值',
        now_deposit_uid:'当前充值用户UID',
        deposittips:'请登录后选择要充值的NFR，确认选择无误后点击充值按钮开始充值。',
        provisionaccount_btn:'开始激活',
        login_btn:'使用Flow钱包注册&登录',
        logout_btn:'退出登录',
        loading:'加载中，请稍后...',
        provision_success:'激活成功',
        provision_fail:'激活失败，请稍后重试',
        provision_wallet_error:'当前登录钱包与待激活钱包不符，请重新登录',
        deposit_btn:'确认充值Openview',
        deposit_alyphs_num:'充值Openview数量',
        please_enter_depositnum:'请输入充值数量',
        deposit_fail:'充值失败，请检查钱包余额是否充足',
        deposit_success:'充值成功'
    };
    //return lang;
    if(lang == 'en-us'){
        return enus[key];
    }else{
        return zhcn[key];
    }
     
}

export function getQueryVariable(variable)
{
       var query = window.location.search.substring(1);
       var vars = query.split("&");
       for (var i=0;i<vars.length;i++) {
               var pair = vars[i].split("=");
               if(pair[0] == variable){return pair[1];}
       }
       return(false);
}
